import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
  return (
    <div className="works">
      <Card
        icon={faBriefcase}
        title="Work"
        body={
          <div className="works-body">
            <div className="work">
              <img src="./carepilot.png" alt="carepilot" className="work-image" />
              <div className="work-title">CarePilot</div>
              <div className="work-subtitle">SVP Engineering</div>
              <div className="work-duration">2024 - Present</div>
            </div>

            <div className="work">
              <img src="./sai.jpeg" alt="securedai" className="work-image" />
              <div className="work-title">Secured AI</div>
              <div className="work-subtitle">Founder</div>
              <div className="work-duration">2022 - 2024</div>
            </div>

            <div className="work">
              <img src="./blc.webp" alt="backlotcars" className="work-image" />
              <div className="work-title">BacklotCars</div>
              <div className="work-subtitle">Product Manager</div>
              <div className="work-duration">Summer 2023</div>
            </div>

            <div className="work">
              <img src="./blc.webp" alt="backlotcars" className="work-image" />
              <div className="work-title">BacklotCars</div>
              <div className="work-subtitle">Product Manager</div>
              <div className="work-duration">Summer 2022</div>
            </div>

            <div className="work">
              <img src="./twitter.png" alt="twitter" className="work-image" />
              <div className="work-title">Twitter</div>
              <div className="work-subtitle">Software Engineer</div>
              <div className="work-duration">2019 - Present</div>
            </div>

            <div className="work">
              <img
                src="./jmarshall.jpeg"
                alt="jmarshallsolutions"
                className="work-image"
              />
              <div className="work-title">J Marshall Solutions</div>
              <div className="work-subtitle">IT Services Specialist</div>
              <div className="work-duration">2022</div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Works;
